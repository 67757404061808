<!--
 * @Author: Dyf
 * @Date: 2023-11-17 14:19:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-17 15:47:49
 * @Description: 
-->
<style lang="scss" scoped>
.distribute {
  @include innerPage;
  @include pageHead(-42px);
  .page-inner {
    width: 100%;
    height: calc(100% - 140px);
    box-sizing: border-box;
    padding-top: 26px;
    .wrapper {
      width: calc(100% + 20px);
      height: 100%;
      box-sizing: border-box;
      padding-right: 20px;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox(space-between, flex-start);

      &-left {
        width: calc(100% - 334px);
        height: 100%;
        .show-box{
          width: 100%;
          overflow-y: auto;
          height: calc(100% - 78px);
        }
        .operation{
          background-color: #FFFFFF;
          padding: 10px 10px 10px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          margin-bottom: 20px;
          width: 100%;
          box-sizing: border-box;
          position: sticky;
          top: 0;
          z-index: 20;
        }
        .question{
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          height: calc(100% - 92px);
          position: relative;
          background-color: #FFFFFF;
          border-radius: 10px;
          padding: 10px 10px 10px 20px;
          box-sizing: border-box;
          margin-bottom: 20px;
        }
      }
      &-right {
        width: 304px;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        padding-bottom: 20px;
      }
    }
    .question-wrapper {
      width: 100%;
      background: #f0f3fa;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 28px 46px;
      position: relative;
      h3 {
        color: #414141;
        line-height: 42px;
        margin-bottom: 20px;
        text-align: center;
      }
      .magnifyicon{
        position: absolute;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #6340c8;
        background: #d5cdea;
        cursor: pointer;
        right: 0;
        top: 0;
        border-radius: 0 0 0 18px;
      }
      .question,
      .question-compose {
        @include flexBox;
        align-items: flex-start;
        width: 100%;
        height: calc(100% - 92px);
        position: relative;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 10px 10px 10px 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        .checkbox{
          display: flex;
          align-items: baseline;
          height: 24px;
          width: 24px;
          margin-top: 10px;
          box-sizing: border-box;
        }
        ::v-deep{ 
          .el-icon-success{
            font-size: 24px;
            color: #6c4ecb;
          }
          .el-checkbox{
            padding-right: 0px;
            margin-top: 0px;
          }
          
        }
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--type {
          flex-shrink: 0;
          height: 34px;
          line-height: 34px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 4px 8px 0 4px;
          font-size: 18px;
          padding: 0 14px;
        }

        &--num {
          color: #1f1f1f;
          font-size: 20px;
          line-height: 34px;
          width: 44px;
          text-align: right;
          margin-top: 4px;
          flex-shrink:0;
        }
        &--data {
          flex-grow: 1;

          h5 {
            line-height: 42px;
            font-weight: bold;
            color: #1f1f1f;
            @include flexBox;
            align-items: flex-start;
            font-size: 24px;
            span {
              text-align: justify;
              margin: 0 10px;
            }

            .el-image {
              flex-shrink: 0;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;
            font-size: 18px;
            align-items: flex-start;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }
        .compose-item {
          margin-top: 10px;
          h5 {
            line-height: 42px;
              color: #1f1f1f;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              margin: 10px 0 10px;
              position: relative;

            .span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
          .question--options {
            margin-left: 8px;
          }
        }
        ::v-deep {
          .el-loading-mask {
            background: #f0f3fa;
          }
        }
      }
    }
    .operation-btns {
      height: 60px;
      box-sizing: border-box;
      padding-top: 18px;
      @include flexBox;
      align-items: baseline;

      .change-btn {
        margin-left: auto;
      }
    }
  }
}
.dialog-container--distribute {
  box-sizing: border-box;
  padding: 24px 0;

  .date-picker {
    ::v-deep {
      .el-input {
        width: 100%;

        &__inner {
          padding: 0 44px 0 26px;
        }
      }
    }
  }
}
.students {
    width: 100%;
    height:100%;
    flex-grow: 1;
    background: #f0f3fa;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 24px 18px;
    flex-direction: column;
    @include flexBox(space-between);

    .statistics-board {
      width: 100%;
      height: 130px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba($color: #bcbcbc, $alpha: 0.34);
      flex-shrink: 0;
      flex-direction: column;
      @include flexBox(center, flex-start);

      p {
        width: calc(100% - 26px);
        height: 34px;
        color: #2e2e2e;
        @include flexBox(space-between);

        span {
          &:first-child {
            font-size: 16px;
          }
        }

        b {
          font-size: 24px;
        }
      }
    }

    .submitNumber{
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 10px 0;
      .item{
        text-align: center;
        .title{
          line-height: 22px;
          margin-bottom: 4px;
          font-size: 16px;
          color: #666666;
        }
        .p{
          color: #6340c8;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .student-list {
      width: calc(100% + 8px);
      flex-grow: 1;
      margin-top: 30px;
      box-sizing: border-box;
      padding: 0 16px;
      overflow: hidden;
      overflow-y: auto;

      li {
        width: 100%;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
        .stuser_info{
          height: 62px;
          margin-bottom: 10px;
          @include flexBox;
        }
        .el-avatar{
          flex-shrink: 0;
        }
        .widht100{
          width: 100%;
        }
        .name_type{
          @include flexBox(space-between,center);
          padding-left: 10px;
        }
        .result{
            @include flexBox(space-between,center);
            padding-left: 10px;
            margin-top: 10px;
            .item{
              color:#FF0000
            }
            .correct{
              color: gray;
            }
        }
        .name {
          font-size: 16px;
          color: #2b2b2b;
          @include ellipsisMultiline(2)
        }

        .connect-status {
          color: rgba($color: #7a7a7a, $alpha: 0.39);
          flex-shrink: 0;
          @include flexBox;

          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: rgba($color: #7a7a7a, $alpha: 0.39);
            border-radius: 50%;
            margin-right: 4px;
          }

          &.connected {
            color: #2ac293;

            i {
              background: #2ac293;
            }
          }
          &.submit {
            color: #6340c8;

            i {
              background: #6340c8;
            }
          }
        }
        .answer{
          display: inline-block;
          .item{
            margin: 0px 5px;
            float: left;
          }
          .tooltip-width{
            width: 200px;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.distribute-dialog .el-dialog {
  transform: translateY(-80%);
}

.selectimg{
	max-width: 100%;
	display: block;
}

.magnifying{
  .question{
    &--type{
      height: 44px !important;
      line-height: 44px !important;
      font-size: 28px !important;
      padding: 0 20px !important;
    }
    &--data h5{
      line-height: 52px !important;
      font-size: 30px !important;
    }
    &--num{
      line-height: 44px !important;
      font-size: 24px !important;
      width: 50px !important;
    }
    &--options .option{
      font-size: 24px !important;
      line-height: 40px !important;
    }
  }
  .checkbox{
    margin-top: 15px !important;
  }
}
</style>

<template>
  <section class="distribute">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
      <div class="head-wrapper">
        <breadcrumb />
        <el-button type="custom_primary" @click="$router.go(-1)" size="medium">返回</el-button>
        <el-button type="warning" @click="resetap" icon="el-icon-refresh" size="medium">重启设备</el-button>
      </div>
    </div>
    <div class="page-inner">
      <div class="wrapper">
        <div class="wrapper-left">
          <div class="show-box">
            <div class="question-wrapper" :class="fullscreen?'magnifying':''"  v-if="isEmptyObject(que_list)">      
              <h3 class="bold">校内作业
                <span v-if="sTime!=0" style="font-size:14px;">（答题剩余时间：{{sTime}}秒）</span>
              </h3>
              <!-- 全屏/还原 -->
              <div class="magnifyicon" style="--size: 18px" @click="setfullscreen()">
                <span v-if="fullscreen" class="iconfont">&#xe68e;</span>
                <span v-else class="iconfont">&#xe68c;</span>
              </div>
              
              <div class="operation">
                  <el-checkbox size="small" :indeterminate="someCheck" v-model="allCheck" label="全选" @change="allcheckchange"></el-checkbox>
              </div>
              <div v-for="(current_que,current_index) in que_list" :key="current_index">
                <div class="question" v-show="!isCompose(current_que.teque_ismakeup)" v-loading="queload">
                  <div class="checkbox"><i class="el-icon-success" v-if="current_que.is_assign==1"></i> <el-checkbox v-else v-model="current_que.check" @change="checkchangeitem(current_que.check,current_index)"></el-checkbox></div>
                  <span class="question--num pf_bold">
                      {{ current_index<9?"0"+(current_index+1):(current_index+1)}}.
                  </span>
                  <span class="question--type">{{ current_que.syque_typ_name }}</span>
                  <div class="question--data">
                    <h5 class="bold" v-if="current_que.teque_title">
                      <div class="span richinline" v-html="current_que.teque_title"></div>
                    </h5>
                    <img class="selectimg" v-if="current_que.teque_title_image" :src="formatfile(current_que.teque_title_image)" alt="">
                    <ul
                      class="question--options"
                      v-show="isChoice(current_que.syque_typ_id)"
                    >
                      <template v-if="current_que.question_item">
                        <li
                          class="option"
                          v-for="o in current_que.question_item"
                          :key="o.teque_ite_id"
                        >
                          <span>
                            {{ o.teque_ite_code }}.
                            <em class="richinline" v-html="o.teque_ite_title"></em>
                          </span>
                          <img class="selectimg" v-if="o.teque_ite_image" :src="formatfile(o.teque_ite_image)" alt="">
                        </li>
                      </template>
                      <template v-if="current_que.item">
                        <li
                          class="option"
                          v-for="o in current_que.item"
                          :key="o.teque_ite_id"
                        >
                          <span>
                            {{ o.teque_ite_code }}.
                            <em class="richinline" v-html="o.teque_ite_title"></em>
                          </span>
                          <img class="selectimg" v-if="o.teque_ite_image" :src="formatfile(o.teque_ite_image)" alt="">
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
                <!-- 组合题 -->
                <div class="question-compose" v-show="isCompose(current_que.teque_ismakeup)" v-loading="queload" >
                  <div class="checkbox"><i class="el-icon-success" v-if="current_que.is_assign==1"></i> <el-checkbox v-else :indeterminate="current_que.someCheck" v-model="current_que.check" @change="checkchangeitem(current_que.check,current_index)"></el-checkbox></div>
                  <span class="question--num pf_bold">
                      {{ current_index<9?"0"+(current_index+1):(current_index+1)}}.
                  </span>
                  <span class="question--type">{{ current_que.syque_typ_name }}</span>
                  <div class="question--data">
                    <h5 class="bold">
                      <div class="span richinline" v-html="current_que.teque_title"></div>
                      <img class="selectimg" v-if="current_que.teque_title_image" :src="formatfile(current_que.teque_title_image)" alt="">
                      <!-- <el-image
                        v-if="current_que.teque_title_image"
                        style="width: 60px; height: 38px"
                        :src="formatfile(current_que.teque_title_image)"
                        fit="cover"
                        :preview-src-list="[formatfile(current_que.teque_title_image)]"
                      /> -->
                    </h5>
                    <!-- 组合小题 -->
                    <div v-for="(childrenitem,que_compose_index) in current_que.children" :key="que_compose_index">
                      <div class="compose-item" v-if="!$isEmpty(childrenitem)">
                        <h5>
                          <div class="checkbox"><i class="el-icon-success" v-if="childrenitem.is_assign==1"></i> <el-checkbox :style="{marginTop:fullscreen?'-15px':'-10px'}" v-else v-model="childrenitem.check" @change="checkchangechilitem(current_index,que_compose_index)"></el-checkbox></div>
                          <span class="pf_bold">{{ que_compose_index + 1 }}、<em class="richinline" v-html="childrenitem.teque_title"></em></span>
                        </h5>
                        <img class="selectimg" v-if="formatfile(childrenitem.teque_title_image)" :src="formatfile(childrenitem.teque_title_image)" alt="">
                        <ul class="question--options" v-show="isChoice(childrenitem.syque_typ_id) " >
                          <li class="option" v-for="o in childrenitem.item" :key="o.teque_ite_id" >
                            <span>{{ o.teque_ite_code }}.<em class="richinline" v-html="o.teque_ite_title"></em></span>
                            <img class="selectimg" v-if="formatfile(o.teque_ite_image)" :src="formatfile(o.teque_ite_image)" alt="">
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="question-wrapper" v-else>  
              <div class="no-data--empty" >
                <img src="@assets/images/no-data3.png" alt="">
                <p>没有题目信息~</p>
              </div>
            </div>
          </div>
          <div class="operation-btns">
            <el-button
              type="custom_primary"
              size="small"
              @click="distributeDialog = true"
              v-loading="btnload"
              v-if="isEmptyObject(que_list)"
            >
              确认下发
            </el-button>
          </div>
        </div>
        <div class="wrapper-right">
          <!-- 学生连接信息 -->
          <div class="students">
            <div class="statistics-board" v-if="submitList.AllNum">
              <p>
                <span>学生总人数：</span>
                <span
                  ><b class="pf_bold">{{ submitList.AllNum }}</b
                  >人</span
                >
              </p>
              <p>
                <span>写字板学生人数：</span>
                <span
                  ><b class="pf_bold">{{ submitList.haveBoardNum }}</b
                  >人</span
                >
              </p>
              <p>
                <span>待连接人数：</span>
                <span
                  ><b class="pf_bold">{{ submitList.waitNum }}</b
                  >人</span
                >
              </p>
            </div>
            <ul class="student-list">
              <li v-for="(item, index) in submitList.userList" :key="index">
                <div class="stuser_info">
                  <el-avatar :size="62" :src="item.stuser_image" />
                  <div class="widht100">
                    <div class="name_type">
                      <p class="name">{{ item.stuser_name }}</p>
                      <p class="connect-status submit" v-if="item.onLineState == 1">
                        <i></i>
                        <span>已连接</span>
                      </p>
                      <p class="connect-status" v-else>
                        <i></i>
                        <span>未连接</span>
                      </p>
                    </div>
                    <div class="result">
                      <div class="item correct">已答对:{{item.succNum}}</div>
                      <div class="item">已答错:{{item.errorNum}}</div>
                    </div>
                  </div> 
                </div>
                <div class="answer">
                  <div class="item" v-for="(item2,index2) in item.images" :key="index2">
                    <el-tooltip transition="disabled" effect="dark" placement="top">
                      <template slot="content" v-if="item2.title">
                          <div>{{inIdgetindex(item2.que_id)}}</div>
                      </template>
                      <el-image style="width: 20px;height: 30px;border: 1px solid #999999;" v-if="item2.url" fit="cover" :src="item2.url" :preview-src-list="[item2.url]" />
                    </el-tooltip>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 设置作业完成时间 -->
    <el-dialog
      title="设置作业完成时间"
      width="430px"
      :visible.sync="distributeDialog"
      class="distribute-dialog"
      @close="hideDistributeDialog"
    >
      <div class="dialog-container--distribute">
        <el-input
          v-model="home_finish_time"
          placeholder="请输入作业完成时间"
          @input="home_finish_time = iptInit(home_finish_time)"
        >
          <span slot="append">/分钟</span>
        </el-input>
      </div>
      <div slot="footer">
        <el-button
          type="custom_primary"
          :disabled="!home_finish_time"
          size="small"
          @click="distributeQue"
        >
          确认下发
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  $getSchoolHomework,
  $get_school_submit_list,
  $teachHomeworkDetail2,
  $batch_assign_schoolHomework,
} from "@api/teaching";
import { formatFile, iptFloat } from "@utils";
var datein;
export default {
  beforeRouteLeave(to, from, next) {
    // 执行你的事件逻辑
    this.timeOutState = false;
    console.log('离开当前页面');

    // 调用next()继续导航
    next();
  },
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    /** 判断是否是选择题 */
    isChoice() {
      const choiceTypeIds = [1, 2, 5];
      return function (val) {
        return choiceTypeIds.indexOf(val) != -1;
      };
    },
    /** 判断是否是组合题 */
    isCompose() {
      return function (val) {
        return val == 10;
      };
    },
    iptInit() {
      return function (val) {
        return iptFloat(val);
      };
    },
  },
  data() {
    return {
      teclaId: null, // 授课id
      que_list: [], // 展示面板题目列表
      que_index: 0, // 当前展示题目下标
      teles_hom_id: null, // 操作的作业id
      que_compose_index: 0, // 当前展示组合题目小题下标
      current_que: {}, // 当前展示题目
      home_finish_time: null, // 校内作业完成时间
      distributeDialog: false, // 打开设置作业完成时间弹窗
      btnload: false, // 下发按钮加载
      queload: false, // 题目加载
      sccla_id: 0, // 班级id 查询手写板列表，获取默认数据使用
      submitList: {}, // 学生链接数据
        // 答题时间倒计时
      sTime:0,
      timeOutState:true,//答题倒计时是否执行
      //批量加入
      allCheck:false,
      //部分选中
      someCheck:false,
      //选中小题后提交获取的组合id
      teles_pra_gro_id:0,
      //全屏展示
      fullscreen:false,
    };
  },
  /** 销毁页面上窗口监听事件 */
  destroyed() {
    //停止时间轮循
    clearInterval(datein);
    sessionStorage.removeItem("tesch_work_gro_id")
  },
  created() {
    this.teclaId = this.$route.params.tecla_id;
    this.sccla_id = this.$route.params.sccla_id;
    this.teles_hom_id = this.$route.params.homework;
    //获取本地存储的答题提交组合
    this.teles_pra_gro_id =sessionStorage.getItem("tesch_work_gro_id")?sessionStorage.getItem("tesch_work_gro_id"):0;
    this.getHomework();
    this.get_school_submit_list();
  },
  methods: {
    async getHomework() {
      let { data } = await $getSchoolHomework(this.teles_hom_id);
      this.que_list = data;
      //停止时间轮循
      clearInterval(datein);
      //循环获取手写板信息
      datein = setInterval(() => {
        this.get_school_submit_list();
      }, 3000);
      this.$forceUpdate();
    },/**
     * 手写板
     * ---- 获取手写板在线数据
     */
    async get_school_submit_list() {
      let queid = this.teles_pra_gro_id;
      let res = await $get_school_submit_list(this.sccla_id, queid);
      this.submitList = res.data || {};
    },
    // 判断对象是否为空对象
    isEmptyObject(list) {
      return list.length>0;
    },
    /** 关闭设置作业完成时间弹窗 */
    hideDistributeDialog() {
      this.home_finish_time = null;
      this.distributeDialog = false;
    },
     //倒计时函数
     repeat(time, callbcak) {
      if (time > 0) {
          callbcak(time)
          time--
          setTimeout(() => {
            if(this.sTime!=0){
              this.repeat(time, callbcak)
            }
          }, 1000)
      } else {
          callbcak(0)
      }
    },
    /** 题目下发 */
    async distributeQue() {
      let numbers=Number(this.home_finish_time) * 60
      if(numbers <=0){
        this.$message({
          type: "error",
          duration: 1500,
          message: "请填写大于0的时间",
        });
        return false;
      }
      this.btnload = true;
      let list = this.que_list
      //题目ids
      let teque_ids=[]
      list.map(item=>{
        if(!this.isCompose(item.teque_ismakeup)){
          if(item.check){
            teque_ids.push(item.teque_id)
          }
        }else{
          item.children.map(item2=>{
            if(item2.check){
              teque_ids.push(item2.teque_id) 
            }
          })
        }
      })

      if(teque_ids.length==0){
        this.$message({
          message: '请选择需要下发的题',
          type: 'warning'
        });
        this.btnload = false;
        return false
      }
      this.hideDistributeDialog();
      let res = await $batch_assign_schoolHomework({tecla_id:this.teclaId,teles_hom_id:this.teles_hom_id, teque_ids:teque_ids.toString(),timeout: numbers,});;
      this.btnload = false;
      this.timeOutState = true;
      if (res) {
        // 倒计时处理
        this.sTime=0
        setTimeout(()=>{
          this.repeat( numbers,(time)=>{
            this.sTime=time
            if(time==0&&this.timeOutState){
              this.$alert('答题时间已结束!', '提示', {
                confirmButtonText: '确定',
                callback: action => {}
              });
              sessionStorage.removeItem("tesch_work_gro_id")
            }
          })
        },1000)
        // 获取返回的那组答题id
        this.teles_pra_gro_id = res.data.teles_pra_gro_id
        //存储正在答题的数据
        sessionStorage.setItem("tesch_work_gro_id",res.data.teles_pra_gro_id);
        // 重新调整列表
        list.map(item=>{
          if(!this.isCompose(item.teque_title)){
            if(item.check){
              item.is_assign=1
              item.check=false
            }
          }else{
            item.children.map(item2=>{
              if(item2.check){
                item2.is_assign=1
                item2.check=false
              }
            })
          }
        })
        this.current_que_list=list
        //修改全选按钮选中状态
        this.allCheck=false
        this.someCheck=false
        this.getHomework()
        this.$forceUpdate();
        this.$message({
          type: "success",
          duration: 1500,
          message: "题目下发成功",
        });
      }
    },
    //点击全部
    allcheckchange(val){
      let listData=this.que_list
      this.allcheck=val
      this.someCheck=false
      listData.map(item=>{
        item.someCheck=false
        if(item.is_assign==2){
          item.check=val
        }
        // 判断是否是组合提
        if(item.children && item.children.length>0){
          item.children.map(item2=>{
            if(item2.is_assign==2){
              item2.check=val
            }
          })
        }
      })
      this.que_list=listData
      this.$forceUpdate();
    },
    //点击单个
    checkchangeitem(check,index){
      let listitem=this.que_list[index]
      if(listitem.children && listitem.children.length>0){
        listitem.children.map(item=>{
          if(item.is_assign==2){
            item.check=check
          }
        })
      }
      this.que_list[index]=listitem
      this.getallcheck()
      this.$forceUpdate();
    },
    //点击组合提子集
    checkchangechilitem(index1,index2){
      let listitem=this.que_list[index1]
      let childrenitem=this.que_list[index1].children[index2]
      // 组合提里面选中的数量
      let clicklength=0
      this.que_list[index1].children.map(item=>{
        if(item.check){
          clicklength++
        }
      })
      if(clicklength>0){
        if(this.que_list[index1].children.length==clicklength){
          this.que_list[index1].check=true
          this.que_list[index1].someCheck=false
        }else{
          this.que_list[index1].check=false
          this.que_list[index1].someCheck=true
        }
      }else{
        this.que_list[index1].check=false
        this.que_list[index1].someCheck=false
      }
      this.getallcheck()
      this.$forceUpdate();
    },
    //获取是否全部选中
    getallcheck(){
      // 组合提里面选中的数量
      let clicklength=0
      this.que_list.map(item=>{
        if(item.check){
          item.someCheck=false
          clicklength++
        }
      })
      if(clicklength>0){
        //全部选中
        if(this.que_list.length==clicklength){
          this.allCheck=true
          this.someCheck=false
        }else{
          // 部分选中
          this.allCheck=false
          this.someCheck=true
        }
      }else{
        this.allCheck=false
        this.someCheck=false
      }
      this.$forceUpdate();
    },
    //重启ap设备
    resetap(){
      this.$alert('设备已关闭,需要重新启动AP设备？', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          //打开手写板
          let url ="RunZhiYuanTongApServer://" +this.sccla_id +",lisi,wusong";
          window.open(url, "_blank");
        }
      });
    },
    //通过id获取下标
    inIdgetindex(id){
      let indexopt=0
      // 遍历父级id
      this.que_list.map((item,index)=>{
        if(item.teque_id==parseInt(id)){
          indexopt=(index+1)<9?("0"+(index+1)):(index+1)
        }
        if(item.syque_typ_id==14){
          item.children.map((item2,index2)=>{
            if(item2.teque_id==parseInt(id)){
              indexopt=((index+1)<9?("0"+(index+1)):(index+1))+"_"+((index2+1)<9?("0"+(index2+1)):(index2+1))
            }
          })
        }
      })
      return indexopt
    },
    //全屏
    setfullscreen(){
      if(this.fullscreen){
        document.exitFullscreen();
        this.fullscreen=false
      }else{
        document.documentElement.requestFullscreen();
        this.fullscreen=true
      }
    },
  },
};
</script>